<template>
  <div>
    <div role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#" v-b-toggle.accordion-1 variant="primary" class="accordion-title">New Stickers ({{
            newStickers.length }})</b-button>
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <template v-if="newStickers.length > 0">
              <b-badge class="mr-2" variant="primary" v-for="item in newStickers" :key="item">{{ item }}</b-badge>
            </template>
            <i v-else>No new stickers generated.</i>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block href="#" v-b-toggle.accordion-2 variant="primary" class="accordion-title">Reprint Stickers ({{
            reprintStickers.length }})</b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <template v-if="reprintStickers.length > 0">
              <b-badge class="mr-2" variant="primary" v-for="item in reprintStickers" :key="item">{{ item }}</b-badge>
            </template>
            <i v-else>No reprint stickers generated.</i>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <label>
      Sticker Range: <strong>{{ stickerRange.first }} - {{ stickerRange.last }}</strong> <br />
      Total Count: <strong>{{ totalStickers }}</strong>
    </label>
    <div class="row mt-3" v-if="reprintStickers.length > 0">
      <div class="col-md-12">
        <div class="form-group">
          <label>
            Do you still want to print these? Please provide your reason. &nbsp;
            <i id="warning-notif" class="fa fa-exclamation-circle text-danger"
              v-b-tooltip.hover.top="'This is required field!'"></i>
          </label>
          <textarea class="form-control" @input="$emit('onInputReason', $event.target.value)" rows="3"
            v-text="reason"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmStickerParams',
  props: ['item', 'reason'],
  computed: {
    newStickers() {
      if (!this.item.stickers) return [];
      return this.item.stickers.new;
    },
    reprintStickers() {
      if (!this.item.stickers) return [];
      return this.item.stickers.reprint;
    },
    totalStickers() {
      if (!this.item.stickers) return 0;
      return [...this.item.stickers.new, ...this.item.stickers.reprint].sort().length
    },
    stickerRange() {
      return {
        first: this.item.stickerRange ? this.item.stickerRange.first : '',
        last: this.item.stickerRange ? this.item.stickerRange.last : ''
      }
    }
  }
}
</script>

<style scoped>
#warning-notif {
  cursor: pointer;
}

.accordion-title {
  color: white !important;
}</style>