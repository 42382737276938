<template>
	<ValidationObserver v-slot="{ valid }">
		<b-modal
			id="generate-stickers"
			size="lg"
			@close="onCancel"
			no-close-on-backdrop
		>
			<template v-slot:modal-title>Generate Sticker</template>

			<loading
				:active.sync="isLoading"
				loader="spinner"
				color="#20A8D8"
				:is-full-page="false"
			/>

			<b-form @submit.stop.prevent="onSubmit" @reset="onCancel" novalidate>
				<div class="row">
					<div class="col-md-6">
						<div class="form-row">
							<div class="col-md-4">
								<div class="form-group text-right">
									<label for="asset-types" class="mr-2 mt-1">Company</label>
								</div>
							</div>
							<div class="col-md-8">
								<b-form-group>
									<ValidationProvider
										name="Company"
										rules="required"
										v-slot="{ errors }"
									>
										<b-form-select
											v-model="form.company"
											:options="filteredOptions.companies"
										/>
										<span v-show="errors.length > 0" class="help-block">{{
											errors[0]
										}}</span>
									</ValidationProvider>
								</b-form-group>
							</div>
						</div>
						<div class="form-row">
							<div class="col-md-4">
								<div class="form-group text-right">
									<label for="asset-types" class="mr-2 mt-1">Asset Type</label>
								</div>
							</div>
							<div class="col-md-8">
								<b-form-group>
									<ValidationProvider
										name="Asset Type"
										rules="required"
										v-slot="{ errors }"
									>
										<b-form-select
											v-model="form.assetType"
											:options="filteredOptions.assetTypes"
										/>
										<span v-show="errors.length > 0" class="help-block">{{
											errors[0]
										}}</span>
									</ValidationProvider>
								</b-form-group>
							</div>
						</div>
						<div class="form-row">
							<div class="col-md-4">
								<div class="form-group text-right">
									<label for="asset-types" class="mr-2 mt-1">
										# of Stickers
									</label>
								</div>
							</div>
							<div class="col-md-8">
								<b-form-group>
									<b-form-input
										name="number of stickers"
										type="number"
										v-model="form.noOfStickers"
										min="1"
										max="10"
										v-validate="'required|numeric|min_value:1|max_value:10'"
									/>
									<span
										v-show="errors.has('number of stickers')"
										class="help-block"
										>{{ errors.first('number of stickers') }}</span
									>
								</b-form-group>
							</div>
						</div>
						<div class="form-row">
							<div class="col-md-4">
								<div class="form-group text-right">
									<label for="asset-types" class="mr-2 mt-1"
										>Asset Type Code</label
									>
								</div>
							</div>
							<div class="col-md-8">
								<b-form-group>
									<ValidationProvider
										name="Asset Type Code"
										rules="required"
										v-slot="{ errors }"
									>
										<b-form-select
											v-model="form.assetTypeCode"
											:options="filteredOptions.assetTypeCodes"
										/>
										<span v-show="errors.length > 0" class="help-block">{{
											errors[0]
										}}</span>
									</ValidationProvider>
								</b-form-group>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="form-row">
							<div class="col-md-4">
								<div class="form-group text-right">
									<label for="asset-types" class="mr-2 mt-1">
										Sticker Prefix
									</label>
								</div>
							</div>
							<div class="col-md-8">
								<b-form-group>
									<b-form-input
										:value="
											form.assetTypeCode ? form.assetTypeCode.prefix : null
										"
										readonly
									/>
								</b-form-group>
							</div>
						</div>
						<div class="form-row">
							<div class="col-md-4">
								<div class="form-group text-right">
									<label for="asset-types" class="mr-2 mt-1"
										>Sticker Setup</label
									>
								</div>
							</div>
							<div class="col-md-8">
								<b-form-group>
									<ValidationProvider
										name="Sticker Setup"
										rules="required"
										v-slot="{ errors }"
									>
										<b-form-select
											v-model="form.selectedStickerSetup"
											:options="stickerSetups"
										></b-form-select>
										<span v-show="errors.length > 0" class="help-block">{{
											errors[0]
										}}</span>
									</ValidationProvider>
								</b-form-group>
							</div>
						</div>

						<template v-if="form.selectedStickerSetup">
							<div class="form-row">
								<div class="col-md-4">
									<div class="form-group text-right">
										<label for="asset-types" class="mr-2 mt-1"
											>Start Value</label
										>
									</div>
								</div>
								<div class="col-md-8">
									<b-form-group>
										<ValidationProvider
											name="Start Value"
											rules="required|alpha_num|ayun_stickers|min:6|max:6"
											v-slot="{ errors }"
										>
											<b-form-input
												v-model="startVal"
												placeholder="(6) digit code without prefix."
											/>
											<span v-show="errors.length > 0" class="help-block">{{
												errors[0]
											}}</span>
										</ValidationProvider>
									</b-form-group>
								</div>
							</div>

							<div class="form-row" v-if="form.selectedStickerSetup == 'range'">
								<div class="col-md-4">
									<div class="form-group text-right">
										<label for="asset-types" class="mr-2 mt-1">End Value</label>
									</div>
								</div>
								<div class="col-md-8">
									<b-form-group>
										<ValidationProvider
											name="End Value"
											rules="required|alpha_num|ayun_stickers|min:6|max:6"
											v-slot="{ errors }"
										>
											<b-form-input
												v-model="endVal"
												placeholder="(6) digit code without prefix."
											/>
											<span v-show="errors.length > 0" class="help-block">{{
												errors[0]
											}}</span>
											<span v-show="withSetupError.status" class="help-block">{{
												withSetupError.message
											}}</span>
										</ValidationProvider>
									</b-form-group>
								</div>
							</div>

							<div
								class="form-row"
								v-else-if="form.selectedStickerSetup == 'count'"
							>
								<div class="col-md-4">
									<div class="form-group text-right">
										<label for="asset-types" class="mr-2 mt-1">Quantity</label>
									</div>
								</div>
								<div class="col-md-8">
									<ValidationProvider
										name="Quantity"
										rules="required|numeric|min_value:1|max_value:5000"
										v-slot="{ errors }"
									>
										<b-form-group>
											<b-form-input v-model="form.stickerParams.count" />
											<span v-show="errors.length > 0" class="help-block">{{
												errors[0]
											}}</span>
											<span v-show="withSetupError.status" class="help-block">{{
												withSetupError.message
											}}</span>
										</b-form-group>
									</ValidationProvider>
								</div>
							</div>
						</template>

						<div class="form-row" v-if="form.selectedStickerSetup">
							<div class="col-md-4">
								<div class="form-group text-right">
									<label for="asset-types" class="mr-2 mt-1"></label>
								</div>
							</div>
							<div class="col-md-8">
								<b-form-group>
									<b-button
										type="submit"
										variant="primary"
										block
										:disabled="
											getValidationRequestStatus ||
											errors.items.length > 0 ||
											withSetupError.status ||
											!valid
										"
									>
										Generate
									</b-button>
								</b-form-group>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="loadConfirmationStickerParams && valid">
					<div class="col-md-12">
						<ConfirmStickerParams
							:item="validationResult"
							:reason="form.reason"
							@onInputReason="onInputReason"
						/>
					</div>
				</div>
			</b-form>
			<template v-slot:modal-footer>
				<b-button @click="onCancel">Cancel</b-button>
				<b-button
					variant="primary"
					@click="onProceed"
					:disabled="!isValidToProceed(valid)"
					>Proceed</b-button
				>
			</template>
		</b-modal>
	</ValidationObserver>
</template>

<script>
// Component
import ConfirmStickerParams from './ConfirmStickerParams';

// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// Others
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import * as form from '@/utils/formCollectionUtil';
import { mapGetters } from 'vuex';
import pluralize from 'pluralize';
import config from '@/config/env-constants';
import {
	ValidationProvider,
	ValidationObserver,
	Validator,
} from 'vee-validate';

Validator.extend('ayun_stickers', {
	getMessage: (field) => 'The ' + field + ' must not contain letters i, l, o.',
	validate: (value) => {
		return !(value.includes('i') || value.includes('l') || value.includes('o'));
	},
});

export default {
	name: 'GenerateStickerDialog',
	components: {
		Loading,
		ConfirmStickerParams,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			isLoading: false,
			form: {
				company: config.companyDefaultValue,
				assetType: null,
				assetTypeCode: null,
				selectedStickerSetup: '',
				stickerParams: {
					startVal: '',
					endVal: '',
					count: 0,
				},
				status: 'Inactive',
				noOfStickers: 1,
				assetCount: 0,
				assets: [],
				reason: '',
			},
			filteredOptions: {
				companies: [{ value: null, text: '- Please select - ' }],
				assetTypes: [{ value: null, text: '- Please select - ' }],
				assetTypeCodes: [{ value: null, text: '- Please select - ' }],
			},
			collections: {
				companies: [],
				assetTypes: [],
				assetTypeCodes: [],
			},
			stickerSetups: [
				{ value: 'range', text: 'By Range' },
				{ value: 'count', text: 'By Count' },
			],
			loadConfirmationStickerParams: false,
			validationResult: {},
			withSetupError: {
				status: false,
				message: '',
			},
		};
	},
	watch: {
		['form.company'](v) {
			this.resetDefaultSelection('assetType');
			this.resetDefaultSelection('assetTypeCode');

			if (v) {
				const id = v.id;
				this.fillAssetTypeOptions(id);
				this.fillAssetTypeCodeOptions(id);
			}
		},
		['form.assetType'](v) {
			this.resetDefaultSelection('assetTypeCode');

			if (v) {
				const id = v.id;
				this.form.noOfStickers = parseInt(v.noOfStickers);
				this.fillAssetTypeCodeOptions(id);
			}
		},
		['form.selectedStickerSetup']() {
			this.clearStickerFields();
		},
		['form.stickerParams.startVal']() {
			this.refreshStickerFieldErrors();
		},
		['form.stickerParams.endVal']() {
			this.refreshStickerFieldErrors();
		},
	},
	computed: {
		...mapGetters('stickerGenerator', [
			'getValidationRequestStatus',
			'getGenerateRequestStatus',
		]),
		startVal: {
			get() {
				return this.form.stickerParams.startVal;
			},
			set(v) {
				this.form.stickerParams.startVal = v.toLowerCase();
			},
		},
		endVal: {
			get() {
				return this.form.stickerParams.endVal;
			},
			set(v) {
				this.form.stickerParams.endVal = v.toLowerCase();
			},
		},
	},
	created() {
		let companies = this.$store.getters.companies;
		let assetTypes = this.$store.getters.assetTypes;
		let assetTypeCodes = this.$store.getters.assetTypeCodes;

		this.fillCollections('companies', companies);
		this.fillCollections('assetTypes', assetTypes);
		this.fillCollections('assetTypeCodes', assetTypeCodes);
		this.fillCompanyOptions();
		this.isLoading = false;
	},
	methods: {
		fillCollections(collection, values) {
			const list = Object.values(values);
			list.forEach((item) => this.collections[collection].push(item));
		},
		addDefaultSelection(option, value) {
			this.filteredOptions[option] = [];
			this.filteredOptions[option].push({ value, text: ' - Please select - ' });
		},
		resetDefaultSelection(option) {
			this.form[option] = null;
			this.filteredOptions[pluralize(option)] = [
				{ value: null, text: '- Please select - ' },
			];
		},
		fillCompanyOptions() {
			this.filteredOptions.companies =
				DropDownItemsUtil.retrieveActiveCompanyItems(
					Object.assign({}, this.collections.companies)
				);
		},
		fillAssetTypeOptions(id) {
			const assetTypes = this.collections.assetTypes;
			const filteredAssetTypes = assetTypes.filter(
				(item) => item.originId === id
			);
			if (filteredAssetTypes.length > 0) {
				filteredAssetTypes.forEach((item) => {
					if (item.assetTagging === 'Default') {
						this.filteredOptions.assetTypes.push({
							value: {
								id: item.id,
								name: item.name,
								brand: item.brand,
								noOfStickers:
									typeof item.noOfStickers === 'undefined'
										? 1
										: item.noOfStickers,
							},
							text: item.name,
						});
					}
				});
			}
		},
		fillAssetTypeCodeOptions(id) {
			const assetTypeCodes = this.collections.assetTypeCodes;
			const filteredAssetTypeCodes = assetTypeCodes.filter(
				(item) => item.assetTypeId === id
			);
			if (filteredAssetTypeCodes.length > 0) {
				this.filteredOptions.assetTypeCodes =
					DropDownItemsUtil.retrieveAssetTypeCodeItems(
						Object.assign({}, filteredAssetTypeCodes)
					);
			}
		},
		onInputReason(v) {
			this.form.reason = v;
		},
		onSubmit() {
			if (this.validateStickerFields()) return;

			this.$validator.validateAll().then((isValid) => {
				if (!isValid) return;

				this.isLoading = true;
				this.loadConfirmationStickerParams = false;
				const type = this.form.selectedStickerSetup;
				const stickerDetails = {
					prefix: this.form.assetTypeCode.prefix,
					brand: this.form.assetType.brand,
					contactNo: this.form.company.contactNo,
					assetTypeId: this.form.assetType.id,
					assetTypeCodeId: this.form.assetTypeCode.id,
				};

				if (type == 'range') {
					stickerDetails.startVal = this.startVal;
					stickerDetails.endVal = this.endVal;
				} else if (type == 'count') {
					stickerDetails.startVal = this.startVal;
					stickerDetails.count = this.form.stickerParams.count;
				} else return this.$toaster.error('Please select sticker setup!');

				const companyDetails = form.assets({
					currUser: this.$store.getters.email,
					...this.form,
				});

				companyDetails.stickerCopies = parseInt(this.form.noOfStickers);
				companyDetails.sessionId = 'SG' + DateUtil.getCurrentTimestamp();

				this.$store
					.dispatch('stickerGenerator/stickerValidation', {
						stickerDetails,
						type,
					})
					.then((result) => {
						this.$store.commit(
							'stickerGenerator/FILL_STICKER_DETAILS',
							stickerDetails
						);
						this.$store.commit(
							'stickerGenerator/FILL_COMPANY_DETAILS',
							companyDetails
						);
						this.validationResult = result;
						this.isLoading = false;

						const stickers = [
							...result.stickers.new,
							...result.stickers.reprint,
						].sort().length;

						if (this.isQuantityExceeded(stickers, 5000)) {
							this.withSetupError = {
								status: true,
								message: 'Quantity must be 5000 or less',
							};
						} else {
							this.loadConfirmationStickerParams = true;
						}
					})
					.catch((_error) => {
						this.isLoading = false;
						this.$toaster.error('Request timeout. Please try again.');
					});
			});
		},
		async onProceed() {
			this.isLoading = true;
			this.$store
				.dispatch('stickerGenerator/generateStickers', {
					reason: this.form.reason,
				})
				.then(() => {
					this.onCancel();
					this.$toaster.success(
						this.$store.state.stickerGenerator.generate_success
					);
					this.$emit('onGenerateSuccess');
					this.isLoading = false;
				})
				.catch((_error) => {
					this.isLoading = false;
					this.$toaster.warning('Error generating stickers. Please try again.');
				});
		},
		onCancel() {
			this.$bvModal.hide('generate-stickers');
			this.form.company = config.companyDefaultValue;
			this.resetDefaultSelection('assetType');
			this.resetDefaultSelection('assetTypeCode');
			this.fillCompanyOptions();
			this.clearStickerFields();
			this.loadConfirmationStickerParams = false;
			this.form.selectedStickerSetup = '';
			this.form.noOfStickers = 1;
			this.form.reason = '';
		},
		validateStickerFields() {
			const validation = { status: false, message: '' };
			let type = this.form.selectedStickerSetup;
			const { startVal, endVal } = {
				startVal: this.startVal,
				endVal: this.endVal,
				count: this.form.stickerParams.count,
			};
			if (type == 'range') {
				if (startVal == endVal) {
					validation.status = true;
					validation.message = 'Start and End value must not be equal.';
				} else if (startVal > endVal) {
					validation.status = true;
					validation.message = 'End value must not be less than start value.';
				}
			}

			this.withSetupError = {
				status: validation.status,
				message: validation.message,
			};
			this.loadConfirmationStickerParams = false;
			return validation.status;
		},
		clearStickerFields() {
			this.form.stickerParams = {
				startVal: '',
				endVal: '',
				count: 0,
			};
		},
		refreshStickerFieldErrors() {
			this.withSetupError = {
				status: false,
				message: '',
			};
		},
		isQuantityExceeded(quantity, limit) {
			return parseInt(quantity) > limit ? true : false;
		},
		isValidToProceed(valid) {
			return (
				!this.isLoading &&
				this.loadConfirmationStickerParams &&
				valid &&
				((this.validationResult.stickers.reprint.length > 0 &&
					this.form.reason) ||
					this.validationResult.stickers.reprint.length == 0)
			);
		},
	},
};
</script>
